const emailTemplateId = {
    onboarding: {
        demoAppointment: 3823241,
        signUp: 3823225,
        customerWon: 3826498,
        onboardingComplete: 4079107,
        bookedContentsActivated: 4079179,
        agentDealConfirmed: 4078668,
        agentReminder1: 4145209,
        agentReminder2: 4148623
    },
    account: {
        delete: 4043519,
        // delete: 3822515,
        changePassword: 3822487,
        updatePaymentData: 3822546,
        paymentInfoChanged: 3822552,
        passwordChangeSuccess: 3829247,
        changePasswordGuest: 4372104,
        passwordChangeGuestSuccess: 4372119
    },
    agent: {
        newLead: 3822563,
        newLeadWithInfo: 5039606,
        notOwner: 3928201,
        exposeReady: 3822601,
        leadWon: 3822590,
        pbLeadWon: 4715621,
        noEnergyCertificate: 4130177,
        exposeApproved: 3822624,
        notaryDataReady: 3822652,
        onpreoReview: 3822667,
        guidesChecklistLeads: 4249721,
        potentialBuyerRegistered: 4340731,
        responsibleUser: 4391706,
        guestAppointmentConfirmationEmail: 4850358,
        activityNotification: 4896479,
        contractConfirmation: 5026253,
        contractConfirmationNoTrial: 6223186,
        contractCancellation: 5040552,
        newTippgeberLead: 6342577
    },
    guest: {
        // lead notification
        newLead: 3823023,
        newOnlineEvalLead: 4135479,
        // newOnlineLeadEvalWithPdf: 4899943,
        newOnlineLeadEvalWithPdf: 4936104, // new template with free valuation text

        // general adjustable
        appointmentSuggestion: 3822982,
        leadNotReachable: 3822971,

        magicLinkWithoutAuth: 5650078,

        // sales process emails
        invite: 3822927,
        magicLink: 5009749,
        brokerDealDone: 3822894, // Maklerauftrag!
        brokerDealDoneDirect: 4769542,

        reminderExpose: 3822883,
        exposeApproval: 3822854,

        requestNotaryData: 3822810,
        reminderNotary: 3822795,
        potentialBuyerNotification: 3822835,
        notaryAppointment: 3822744,

        objectSoldAndReview: 3822721,

        oneClickDealInvite: 4221815,
        priceAssistantPdf: 4271843,
        completedChecklist: 4237496,
        goodByeEmail: 4360386,

        // pipeline mails
        onlineRatingContact1: 4492351,
        onlineRatingContact2: 4492825,
        onlineRatingContact3: 4492834,

        personalRatingContact1: 4492872,
        personalRatingContact2: 4492886,
        personalRatingContact3: 4492899,

        consultationContact1: 4492933,
        consultationContact2: 4492941,
        consultationContact3: 4492965,

        grandSlamOfferContact1: 4492997,
        grandSlamOfferContact2: 4493093,
        grandSlamOfferContact3: 4493118,

        // segmentation mails
        onlineRatingSegmentMail1: 4492842,
        onlineRatingSegmentMail2: 4492853,

        personalRatingSegmentMail1: 4492913,
        personalRatingSegmentMail2: 4492930,

        consultationSegmentMail1: 4492976,
        consultationSegmentMail2: 4492991,

        grandSlamOfferSegmentMail1: 4493134,
        grandSlamOfferSegmentMail2: 4493147,

        offlineSegmentMail1: 4562435,
        offlineSegmentMail2: 4562455,

        chooseSegmentTagMail: 4687327,
        setAppointmentMail: 4849841,
        appointmentConfirmation: 4850142,
        meetingConfirmationMail: 5710067,
        newLeadPersonalEvaluation: 5157163,
        newLeadConsultation: 5157177,
        newLeadGso: 5157185,
        pbLeadCreationMail: 6085039,
        newsletterAcceptanceMail: 6206091,
        financingLeadMail: 6209185,
        tippgeberLeadMail: 6344100
    },
    user: {
        invitationLink: 3965034,
        overdueActivitiesMail: 5035160,
        incomingPBLead: 6075608
    },
    potentialBuyer: {
        newPBLead: 4715601,
        buyerAppInvitation: 4029684,
        sendExpose: 4094515,
        sendViewingAppointment: 4094482,
        sendFinancing: 4094576,
        sendCommissionContract: 4183755,
        sendFinancingDocuments: 4444780,
        sendDataChanges: 4421172,
        sendPBDataChanges: 5598337
    },
    notary: {
        contractMail: 4314781
    },
    bankPartner: {
        partnerOffer: 5869237,
        leadConsent: 5869209,
        partnerAccepted: 5869216
    },
    cerberus: {
        hasDossierMail: 5889201,
        reminderMail: 5898914,
        leadActivationMail1: 5899005,
        leadActivationMail2: 5899039
    },
    support: {
        sendSenderActivationLink: 6019141
    }
};

const generalVariables = ["guestName", "agentName", "agentPortrait", "agentMail", "agentPhone", "agentTitle", "companyAddress", "companyLogo"];
export const cerberusDefaultMails = [
    {
        id: emailTemplateId.cerberus.hasDossierMail,
        title: "Ihre Wertermittung",
        name: "hasDossierMail",
        variables: {
            general: generalVariables,
            cerberus: ["RE_address", "lowerPrice", "upperPrice", "lowerSMPrice", "upperSMPrice", "cerberusLPLink"]
        }
    },
    {
        id: emailTemplateId.cerberus.reminderMail,
        title: "Erinnerung: Wertermittlung",
        name: "reminderMail",
        variables: {
            general: generalVariables,
            cerberus: ["RE_address", "lowerPrice", "upperPrice", "lowerSMPrice", "upperSMPrice", "cerberusLPLink"]
        }
    },
    {
        id: emailTemplateId.cerberus.leadActivationMail1,
        title: "Lead Aktivierungsmail 1",
        name: "leadActivationMail1",
        variables: { general: generalVariables, cerberus: ["cerberusLPLink"] }
    },
    {
        id: emailTemplateId.cerberus.leadActivationMail2,
        title: "Lead Aktivierungsmail 2",
        name: "leadActivationMail2",
        variables: { general: generalVariables, cerberus: ["cerberusLPLink"] }
    }
];

export const listPipelineMails = [
    emailTemplateId.guest.onlineRatingContact1,
    emailTemplateId.guest.onlineRatingContact2,
    emailTemplateId.guest.onlineRatingContact3,
    emailTemplateId.guest.personalRatingContact1,
    emailTemplateId.guest.personalRatingContact2,
    emailTemplateId.guest.personalRatingContact3,
    emailTemplateId.guest.consultationContact1,
    emailTemplateId.guest.consultationContact2,
    emailTemplateId.guest.consultationContact3,
    emailTemplateId.guest.grandSlamOfferContact1,
    emailTemplateId.guest.grandSlamOfferContact2,
    emailTemplateId.guest.grandSlamOfferContact3
];

export const listSegmentationMails = [
    emailTemplateId.guest.onlineRatingSegmentMail1,
    emailTemplateId.guest.onlineRatingSegmentMail2,
    emailTemplateId.guest.personalRatingSegmentMail1,
    emailTemplateId.guest.personalRatingSegmentMail2,
    emailTemplateId.guest.consultationSegmentMail1,
    emailTemplateId.guest.consultationSegmentMail2,
    emailTemplateId.guest.grandSlamOfferSegmentMail1,
    emailTemplateId.guest.grandSlamOfferSegmentMail2,
    emailTemplateId.guest.offlineSegmentMail1,
    emailTemplateId.guest.offlineSegmentMail2,
    emailTemplateId.guest.chooseSegmentTagMail
];

export default emailTemplateId;

import { Address, LocationDocument, User, UserDocument, Workspace } from "@onpreo/database/src";
import { getSignatureByUser, getCustomSignature } from "@onpreo/toolbox/src/client";
import emailTemplateId from "./templateIds";

interface SenderReceiver {
    email: string;
    name?: string;
}

type MailjetVariables<T extends string> = {
    [t in T]: string;
};

interface EmailFactory<T extends string> {
    from: SenderReceiver;
    to: SenderReceiver;
    cc?: SenderReceiver;
    subject?: string;
    variables: MailjetVariables<T>;
    templateId: number;
    replyTo?: string;
}

export type MailjetMail<T extends string> = Omit<EmailFactory<T>, "templateId">;

// ----------------- Onboarding Mails
const demoAppointment = (data: MailjetMail<"agentName" | "date" | "time">) => ({ ...data, templateId: emailTemplateId.onboarding.demoAppointment });
const signUp = (data: MailjetMail<"agentName" | "confirmationAgentEmail">) => ({ ...data, templateId: emailTemplateId.onboarding.signUp });
const customerWon = (data: MailjetMail<"agentName" | "loginUrl">) => ({ ...data, templateId: emailTemplateId.onboarding.customerWon });
const onboardingComplete = (data: MailjetMail<"agentName">) => ({ ...data, templateId: emailTemplateId.onboarding.onboardingComplete });
const bookedContentsActivated = (data: MailjetMail<"agentName" | "loginUrl">) => ({
    ...data,
    templateId: emailTemplateId.onboarding.bookedContentsActivated
});
const agentDealConfirmed = (data: MailjetMail<"agentName" | "bookedPackage" | "bookedContent">) => ({
    ...data,
    templateId: emailTemplateId.onboarding.agentDealConfirmed
});

const agentReminder1 = (data: MailjetMail<"agentName">) => ({
    ...data,
    templateId: emailTemplateId.onboarding.agentReminder1
});

const agentReminder2 = (data: MailjetMail<"agentName">) => ({
    ...data,
    templateId: emailTemplateId.onboarding.agentReminder2
});

export const onboardingEmail = {
    demoAppointment,
    signUp,
    customerWon,
    onboardingComplete,
    bookedContentsActivated,
    agentDealConfirmed,
    agentReminder1,
    agentReminder2
};

// ----------------- Account Mails
const deleteAccount = (data: MailjetMail<"agentName" | "agentCompany" | "cancelReason" | "agentPhone" | "agentEmail" | "agentworkspaceId">) => ({
    ...data,
    templateId: emailTemplateId.account.delete
});
const changePassword = (data: MailjetMail<"agentName" | "passwordReset">) => ({ ...data, templateId: emailTemplateId.account.changePassword });
const updatePaymentData = (data: MailjetMail<"agentName" | "loginUrl">) => ({ ...data, templateId: emailTemplateId.account.updatePaymentData });
const paymentInfoChanged = (data: MailjetMail<"agentName" | "loginUrl">) => ({ ...data, templateId: emailTemplateId.account.paymentInfoChanged });
const passwordChangeSuccess = (data: MailjetMail<"agentName" | "loginUrl">) => ({
    ...data,
    templateId: emailTemplateId.account.passwordChangeSuccess
});
const changePasswordGuest = (data: MailjetMail<"guestName" | "passwordResetGuest" | "agentCompany" | "guestMail" | "companyLogo">) => ({
    ...data,
    templateId: emailTemplateId.account.changePasswordGuest
});

const changePasswordGuestSuccess = (data: MailjetMail<"guestName" | "loginUrlGuest" | "agentCompany" | "guestMail" | "companyLogo">) => ({
    ...data,
    templateId: emailTemplateId.account.passwordChangeGuestSuccess
});

export const accountEmail = {
    deleteAccount,
    changePassword,
    updatePaymentData,
    paymentInfoChanged,
    passwordChangeSuccess,
    changePasswordGuest,
    changePasswordGuestSuccess
};

// ----------------- Agent Mails
export type MailjetAgentEmail<T extends string> = Omit<MailjetMail<T>, "from">;
const fromOnpreo = { name: "onpreo Team", email: "prod@onpreo.de" };

const newLeadAgent = (data: MailjetAgentEmail<"agentName" | "loginUrl">) => ({ ...data, ...fromOnpreo, templateId: emailTemplateId.agent.newLead });
const newLeadWithInfoAgent = (
    data: MailjetAgentEmail<"agentName" | "loginUrl" | "leadSummary" | "questionsSummary" | "valuationPdfTitle" | "valuationPdfLink">
) => ({ ...data, ...fromOnpreo, templateId: emailTemplateId.agent.newLeadWithInfo });

const newTippgeberLeadAgent = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "tippgeberName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.newTippgeberLead
});

const notOwner = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.notOwner
});

const exposeReady = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.exposeReady
});

const leadWon = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.leadWon
});

const pbLeadWon = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.pbLeadWon
});

const noEnergyCertificate = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.noEnergyCertificate
});

const exposeApproved = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort" | "guestName" | "realEstate">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.exposeApproved
});

const notaryDataReady = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.notaryDataReady
});

const onpreoReview = (data: MailjetAgentEmail<"agentName" | "reviewLink" | "realEstateShort" | "guestName">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.onpreoReview
});

const invitationLinkForUser = (data: MailjetAgentEmail<"agentName" | "secondaryAgentName" | "loginUrl">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.user.invitationLink
});

const incomingPBLead = (data: MailjetAgentEmail<"leadSummary" | "loginUrl">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.user.incomingPBLead
});

const guidesChecklistLeads = (data: MailjetAgentEmail<"agentName" | "loginUrl">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.guidesChecklistLeads
});

const potentialBuyerRegistered = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.potentialBuyerRegistered
});

const responsibleUserEmail = (data: MailjetAgentEmail<"agentName" | "loginUrl" | "realEstateShort" | "responsibleAgent">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.responsibleUser
});

const guestAppointmentConfirmationEmail = (
    data: MailjetAgentEmail<"agentName" | "loginUrl" | "guestName" | "appointmentType" | "appointmentAgentDate" | "appointmentAgentTime">
) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.guestAppointmentConfirmationEmail
});

const activityNotificationEmail = (data: MailjetAgentEmail<"activityCreator" | "activityType" | "loginUrlActivity">) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.agent.activityNotification
});

const contractConfirmationMail = (
    data: MailjetAgentEmail<"agentName" | "DayInTwoWeeks" | "PackageType" | "MinContractTime" | "loginUrl" | "trialDays">
) => ({
    ...data,
    templateId: emailTemplateId.agent.contractConfirmation
});

const contractConfirmationMailNoTrial = (data: MailjetAgentEmail<"agentName" | "PackageType" | "MinContractTime" | "loginUrl">) => ({
    ...data,
    templateId: emailTemplateId.agent.contractConfirmationNoTrial
});

const overdueActivitiesMail = (
    data: MailjetAgentEmail<
        | "agentName"
        | "todaysDate"
        | "todaysActivity"
        | "amountOfActivities"
        | "overdueActivitiesLogin"
        | "overdueActivity"
        | "amountOverdue"
        | "todayActivitiesLogin"
    >
) => ({
    ...data,
    ...fromOnpreo,
    templateId: emailTemplateId.user.overdueActivitiesMail
});

const contractCancellationMail = (data: MailjetAgentEmail<"agentName" | "PackageType" | "cancellationDate">) => ({
    ...data,
    templateId: emailTemplateId.agent.contractCancellation
});

export const agentEmail = {
    newLead: newLeadAgent, // done
    newLeadWithInfo: newLeadWithInfoAgent,
    notOwner,
    exposeReady,
    leadWon, // Broker Deal?
    pbLeadWon,
    noEnergyCertificate,
    exposeApproved,
    notaryDataReady,
    onpreoReview,
    invitationLinkForUser,
    guidesChecklistLeads,
    potentialBuyerRegistered,
    responsibleUserEmail,
    guestAppointmentConfirmationEmail,
    activityNotificationEmail,
    contractConfirmationMail,
    contractConfirmationMailNoTrial,
    overdueActivitiesMail,
    contractCancellationMail,
    incomingPBLead,
    newTippgeberLeadAgent
};

// ----------------- Guest Mails
export type GuestEmail =
    | "guestName"
    | "guestMail"
    | "agentName"
    | "agentPortrait"
    | "agentMail"
    | "agentPhone"
    | "agentCompany"
    | "agentTitle"
    | "companyAddress"
    | "companyLogo"
    | "customHTML";

export function parseAddress(address: Address) {
    return `${address?.street} ${address?.house_number}, ${address?.zip} ${address?.town}, ${address?.nation}`;
}

export const footerFromResponsibleUser = (
    responsibleUser: Pick<UserDocument, "workspace" | "location" | "gender" | "meta" | "email" | "mailConfig">,
    workspace: Workspace,
    location?: LocationDocument
) => {
    const getData = () => {
        if (responsibleUser)
            return responsibleUser?.mailConfig?.signature?.useInAutomation
                ? getCustomSignature(responsibleUser, location)
                : getSignatureByUser(responsibleUser, location);
        else
            return workspace.adminUser?.mailConfig?.signature?.useInAutomation
                ? getCustomSignature(workspace.adminUser, location)
                : getSignatureByUser(workspace.adminUser, location);
    };
    const { name, company, phone, email, address, image, logo, jobRole, customHTML } = getData();

    return {
        agentName: name, // responsibleUser?.meta?.name ?? workspace?.adminUser?.meta?.name ?? "Ihr Immobilienmakler",
        agentCompany: company, // workspace?.companyInfo?.company ?? workspace?.billing?.company ?? "-",
        agentPortrait: (responsibleUser?.mailConfig?.signatureImg?.src ??
            workspace?.adminUser?.mailConfig?.signatureImg?.src ??
            image?.src ??
            "") as string, // responsibleUser?.meta?.img?.src ?? workspace?.adminUser?.meta?.img?.src ?? "0",
        agentMail: email, // responsibleUser?.email ?? workspace?.adminUser?.email ?? "support@onpreo.de",
        agentPhone: phone, // responsibleUser?.meta?.phone ?? workspace?.adminUser?.meta?.phone ?? "0000000000",
        agentTitle: jobRole ?? "",
        companyAddress: typeof address === "string" ? address : parseAddress(address),
        companyLogo: logo?.src,
        customHTML
    };
};

export const footerFromWorkspace = (workspace: Workspace) => {
    return footerFromResponsibleUser(workspace.adminUser, workspace);
};

const newLeadGuest = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.newLead });
const newOnlineEvalLeadGuest = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.newOnlineEvalLead });
const newOnlineLeadEvalWithPdf = (data: MailjetMail<GuestEmail | "companyDomain" | "freeValuation">) => ({
    ...data,
    templateId: emailTemplateId.guest.newOnlineLeadEvalWithPdf
});
const newLeadPersonalEvaluation = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.newLeadPersonalEvaluation });
const newLeadConsultation = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.newLeadConsultation });
const newLeadGSO = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.newLeadGso });

const appointmentSuggestion = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.appointmentSuggestion });
const leadNotReachable = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.leadNotReachable });

const invite = (data: MailjetMail<GuestEmail | "loginUrlGuest">) => ({ ...data, templateId: emailTemplateId.guest.invite });
const magicLink = (data: MailjetMail<"magicLink">) => ({ ...data, templateId: emailTemplateId.guest.magicLink });

const magicLinkWithoutAuth = (data: MailjetMail<GuestEmail | "magicLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.magicLinkWithoutAuth
});

const brokerDealDone = (data: MailjetMail<GuestEmail | "loginUrlGuest" | "termsDeal" | "termsOfUse" | "dataSheet">) => ({
    ...data,
    templateId: emailTemplateId.guest.brokerDealDone
});
const brokerDealDoneDirect = (data: MailjetMail<GuestEmail | "termsDeal" | "termsOfUse" | "dataSheet">) => ({
    ...data,
    templateId: emailTemplateId.guest.brokerDealDoneDirect
});
const reminderExpose = (data: MailjetMail<GuestEmail | "loginUrlGuest">) => ({ ...data, templateId: emailTemplateId.guest.reminderExpose });
const exposeApproval = (data: MailjetMail<GuestEmail>) => ({ ...data, templateId: emailTemplateId.guest.exposeApproval });
const requestNotaryData = (data: MailjetMail<GuestEmail | "loginUrlGuest">) => ({ ...data, templateId: emailTemplateId.guest.requestNotaryData });
const reminderNotary = (data: MailjetMail<GuestEmail | "loginUrlGuest">) => ({ ...data, templateId: emailTemplateId.guest.reminderNotary });
const potentialBuyerNotification = (data: MailjetMail<GuestEmail | "date" | "time" | "confirmationURL" | "objectLocation">) => ({
    ...data,
    templateId: emailTemplateId.guest.potentialBuyerNotification
});
const notaryAppointment = (data: MailjetMail<GuestEmail | "date" | "time" | "confirmationURL" | "notarLocation">) => ({
    ...data,
    templateId: emailTemplateId.guest.notaryAppointment
});
const objectSoldAndReview = (data: MailjetMail<GuestEmail | "reviewLinkAgent">) => ({
    ...data,
    templateId: emailTemplateId.guest.objectSoldAndReview
});

const oneClickDealInvite = (data: MailjetMail<GuestEmail | "directDealLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.oneClickDealInvite
});

const priceAssistantPdf = (data: MailjetMail<GuestEmail | "downloadPricePDF">) => ({
    ...data,
    templateId: emailTemplateId.guest.priceAssistantPdf
});

const completedChecklist = (data: MailjetMail<GuestEmail | "agentWebsite" | "contentLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.completedChecklist
});

const goodByeEmail = (data: MailjetMail<GuestEmail>) => ({
    ...data,
    templateId: emailTemplateId.guest.goodByeEmail
});

const onlineRatingContact1 = (data: MailjetMail<GuestEmail | "unsubscribeLink" | "realEstateShort">) => ({
    ...data,
    templateId: emailTemplateId.guest.onlineRatingContact1
});

const onlineRatingContact2 = (data: MailjetMail<GuestEmail | "unsubscribeLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.onlineRatingContact2
});

const onlineRatingContact3 = (data: MailjetMail<GuestEmail | "unsubscribeLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.onlineRatingContact3
});

const personalRatingContact1 = (data: MailjetMail<GuestEmail | "unsubscribeLink" | "realEstateShort">) => ({
    ...data,
    templateId: emailTemplateId.guest.personalRatingContact1
});

const personalRatingContact2 = (data: MailjetMail<GuestEmail | "unsubscribeLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.personalRatingContact2
});

const personalRatingContact3 = (data: MailjetMail<GuestEmail | "unsubscribeLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.personalRatingContact3
});

const consultationContact1 = (data: MailjetMail<GuestEmail | "unsubscribeLink" | "realEstateShort">) => ({
    ...data,
    templateId: emailTemplateId.guest.consultationContact1
});

const consultationContact2 = (data: MailjetMail<GuestEmail | "unsubscribeLink" | "realEstateShort">) => ({
    ...data,
    templateId: emailTemplateId.guest.consultationContact2
});

const consultationContact3 = (data: MailjetMail<GuestEmail | "unsubscribeLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.consultationContact3
});

const grandSlamOfferContact1 = (
    data: MailjetMail<GuestEmail | "unsubscribeLink" | "gsoPromo" | "gsoPromoVoucher" | "realEstateShort" | "gsoDeadline">
) => ({
    ...data,
    templateId: emailTemplateId.guest.grandSlamOfferContact1
});

const grandSlamOfferContact2 = (data: MailjetMail<GuestEmail | "unsubscribeLink" | "gsoPromo" | "gsoPromoEnddate">) => ({
    ...data,
    templateId: emailTemplateId.guest.grandSlamOfferContact2
});

const grandSlamOfferContact3 = (data: MailjetMail<GuestEmail | "unsubscribeLink" | "gsoPromo">) => ({
    ...data,
    templateId: emailTemplateId.guest.grandSlamOfferContact3
});

const onlineRatingSegmentMail1 = (
    data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther" | "realEstateShort">
) => ({
    ...data,
    templateId: emailTemplateId.guest.onlineRatingSegmentMail1
});

const onlineRatingSegmentMail2 = (data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther">) => ({
    ...data,
    templateId: emailTemplateId.guest.onlineRatingSegmentMail2
});

const personalRatingSegmentMail1 = (
    data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther" | "realEstateShort">
) => ({
    ...data,
    templateId: emailTemplateId.guest.personalRatingSegmentMail1
});

const personalRatingSegmentMail2 = (data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther">) => ({
    ...data,
    templateId: emailTemplateId.guest.personalRatingSegmentMail2
});

const consultationSegmentMail1 = (
    data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther" | "realEstateShort">
) => ({
    ...data,
    templateId: emailTemplateId.guest.consultationSegmentMail1
});

const consultationSegmentMail2 = (data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther">) => ({
    ...data,
    templateId: emailTemplateId.guest.consultationSegmentMail2
});

const grandSlamOfferSegmentMail1 = (
    data: MailjetMail<GuestEmail | "gsoPromo" | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther" | "realEstateShort">
) => ({
    ...data,
    templateId: emailTemplateId.guest.grandSlamOfferSegmentMail1
});

const grandSlamOfferSegmentMail2 = (data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther">) => ({
    ...data,
    templateId: emailTemplateId.guest.grandSlamOfferSegmentMail2
});

const offlineSegmentMail1 = (
    data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther" | "realEstateShort">
) => ({
    ...data,
    templateId: emailTemplateId.guest.offlineSegmentMail1
});

const offlineSegmentMail2 = (data: MailjetMail<GuestEmail | "SegmentLinkOne" | "SegmentLinkTwo" | "SegmentLinkThree" | "SegmentLinkOther">) => ({
    ...data,
    templateId: emailTemplateId.guest.offlineSegmentMail2
});

const chooseSegmentTagMail = (data: MailjetMail<GuestEmail | "topicGuest" | "newsletterGuestAccept">) => ({
    ...data,
    templateId: emailTemplateId.guest.chooseSegmentTagMail
});

const setAppointmentMail = (data: MailjetMail<GuestEmail | "appointmentType" | "agentAppointmentLink">) => ({
    ...data,
    templateId: emailTemplateId.guest.setAppointmentMail
});

const appointmentConfirmation = (data: MailjetMail<GuestEmail | "appointmentType" | "appointmentAgentDate" | "appointmentAgentTime">) => ({
    ...data,
    templateId: emailTemplateId.guest.appointmentConfirmation
});

const meetingConfirmationMail = (data: MailjetMail<GuestEmail | "meetingTitle" | "date" | "time">) => ({
    ...data,
    templateId: emailTemplateId.guest.meetingConfirmationMail
});

const pbLeadCreationMail = (data: MailjetMail<GuestEmail>) => ({
    ...data,
    templateId: emailTemplateId.guest.pbLeadCreationMail
});

const newsletterAcceptanceMail = (data: MailjetMail<GuestEmail | "doubleOptInURL">) => ({
    ...data,
    templateId: emailTemplateId.guest.newsletterAcceptanceMail
});

const financingLeadMail = (data: MailjetMail<GuestEmail>) => ({
    ...data,
    templateId: emailTemplateId.guest.financingLeadMail
});

const tippgeberLeadMail = (data: MailjetMail<GuestEmail>) => ({
    ...data,
    templateId: emailTemplateId.guest.tippgeberLeadMail
});

export const guestEmail = {
    newLead: newLeadGuest, // done
    newOnlineEvalLeadGuest,
    newOnlineLeadEvalWithPdf,
    appointmentSuggestion,
    leadNotReachable,
    invite,
    magicLink,
    magicLinkWithoutAuth,
    brokerDealDone,
    brokerDealDoneDirect,
    reminderExpose,
    exposeApproval,
    requestNotaryData,
    reminderNotary,
    potentialBuyerNotification,
    notaryAppointment,
    objectSoldAndReview,
    oneClickDealInvite,
    priceAssistantPdf,
    completedChecklist,
    goodByeEmail,
    onlineRatingContact1,
    onlineRatingContact2,
    onlineRatingContact3,
    personalRatingContact1,
    personalRatingContact2,
    personalRatingContact3,
    consultationContact1,
    consultationContact2,
    consultationContact3,
    grandSlamOfferContact1,
    grandSlamOfferContact2,
    grandSlamOfferContact3,
    onlineRatingSegmentMail1,
    onlineRatingSegmentMail2,
    personalRatingSegmentMail1,
    personalRatingSegmentMail2,
    consultationSegmentMail1,
    consultationSegmentMail2,
    grandSlamOfferSegmentMail1,
    grandSlamOfferSegmentMail2,
    offlineSegmentMail1,
    offlineSegmentMail2,
    chooseSegmentTagMail,
    setAppointmentMail,
    appointmentConfirmation,
    meetingConfirmationMail,
    newLeadPersonalEvaluation,
    newLeadConsultation,
    newLeadGSO,
    pbLeadCreationMail,
    newsletterAcceptanceMail,
    financingLeadMail,
    tippgeberLeadMail
};

type PotentialBuyerEmail =
    | "guestName"
    | "guestMail"
    | "agentName"
    | "agentPortrait"
    | "agentMail"
    | "agentPhone"
    | "agentCompany"
    | "companyLogo"
    | "customHTML";

const newPBLead = (data: MailjetMail<PotentialBuyerEmail>) => ({ ...data, templateId: emailTemplateId.potentialBuyer.newPBLead });

const buyerAppInvitation = (data: MailjetMail<PotentialBuyerEmail | "buyerAppUrl">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.buyerAppInvitation
});

const sendExpose = (data: MailjetMail<PotentialBuyerEmail | "Expose_PDF" | "realEstateShort">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendExpose
});

const sendViewingAppointment = (data: MailjetMail<PotentialBuyerEmail | "viewingAppointmentBuyer">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendViewingAppointment
});

const sendFinancing = (data: MailjetMail<PotentialBuyerEmail | "financingAppointmentBuyer">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendFinancing
});

const sendFinancingDocuments = (data: MailjetMail<PotentialBuyerEmail | "agentTitle" | "companyAddress" | "financingLP">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendFinancingDocuments
});

const sendCommissionContract = (data: MailjetMail<PotentialBuyerEmail | "Commission_Contract">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendCommissionContract
});

const sendDataChanges = (data: MailjetMail<PotentialBuyerEmail | "potentialBuyer" | "dataPrivacyRea" | "revoNoticeRea" | "agbRea">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendDataChanges
});

const sendPBDataChanges = (data: MailjetMail<PotentialBuyerEmail | "potentialBuyer" | "policyInfo">) => ({
    ...data,
    templateId: emailTemplateId.potentialBuyer.sendPBDataChanges
});

export const potentialBuyerEmail = {
    newPBLead,
    buyerAppInvitation,
    sendExpose,
    sendViewingAppointment,
    sendFinancing,
    sendCommissionContract,
    sendFinancingDocuments,
    sendDataChanges,
    sendPBDataChanges
};

type NotaryEmail =
    | "gender"
    | "guestMail"
    | "notaryName"
    | "agentName"
    | "agentPortrait"
    | "agentMail"
    | "agentPhone"
    | "agentCompany"
    | "agentTitle"
    | "companyAddress"
    | "companyLogo"
    | "customHTML";

const sendContract = (data: MailjetMail<NotaryEmail | "realEstate" | "notaryData">) => ({
    ...data,
    templateId: emailTemplateId.notary.contractMail
});

export const notaryEmail = {
    sendContract
};

// isActivated should be false for emails that have by default isActivated: false (in MAILS_LIST)
export const checkTemplateActivation = (emailId, workspace, isActivated = true) => workspace.mailsTemplates?.[emailId] ?? isActivated;

type PartnerEmail =
    | "guestName"
    | "agentName"
    | "agentPortrait"
    | "agentMail"
    | "agentPhone"
    | "agentTitle"
    | "companyAddress"
    | "companyLogo"
    | "customHTML";

const sendPartnerOffer = (data: MailjetMail<PartnerEmail | "consentLink" | "companyName" | "partnerProvisionRate">) => ({
    ...data,
    templateId: emailTemplateId.bankPartner.partnerOffer
});

const sendLeadConsentEmail = (data: MailjetMail<PartnerEmail | "partnerName" | "consentLink" | "reaDSGVO">) => ({
    ...data,
    templateId: emailTemplateId.bankPartner.leadConsent
});

const sendPartnerOfferAcceptanceMail = (
    data: MailjetMail<
        PartnerEmail | "leadInformation" | "importLink" | "leadName" | "leadAddress" | "leadREType" | "leadMail" | "leadTel" | "lowerPrice" | "upperPrice"
    >
) => ({
    ...data,
    templateId: emailTemplateId.bankPartner.partnerAccepted
});

export const partnerEmail = {
    sendPartnerOffer,
    sendLeadConsentEmail,
    sendPartnerOfferAcceptanceMail
};

const sendSenderActivationLink = (data: MailjetMail<"activationLink" | "redirectLink" | "agentMail" | "senderMail">) => ({
    ...data,
    templateId: emailTemplateId.support.sendSenderActivationLink
});

export const supportEmail = {
    sendSenderActivationLink
};
